<template>
  <v-container fluid fill-height class="loginOverlay">
        <v-layout flex align-center justify-center>
            <v-col
                cols="12"
                xm="12"
                sm="12"
                md="6"
                lg="4"
                xl="2"
            >
                <v-card
                    class="w-100"
                    outlined
                >
                    <v-col cols="12">
                        <v-row
                            justify="center"
                        >
                            <v-card-title>
                                Register
                            </v-card-title>
                        </v-row>
                    </v-col>
                    <v-form v-model="valid" ref="form">
                        <v-col cols="12">
                            <v-text-field
                                label="Enter your user name"
                                v-model="registrationInfo.user_name"
                                :rules="usernameRules"
                                placeholder=""
                                required
                            ></v-text-field>
                            <v-text-field
                                label="Enter your e-mail address"
                                v-model="registrationInfo.email"
                                :rules="emailRules"
                                placeholder=""
                                required
                            ></v-text-field>
                            <v-text-field
                                label="Pick your password"
                                v-model="registrationInfo.password"
                                min="8"
                                :append-icon="e1 ? 'visibility' : 'visibility_off'"
                                @click:append="() => (e1 = !e1)"
                                @mouseup:append="()=>(e1 = !e1)"
                                :type="e1 ? 'password' : 'text'"
                                :rules="passwordRules"
                                counter
                                required
                            ></v-text-field>
                            <v-row
                                justify="center"
                                class="pt-4"
                            >
                                <v-btn @click="submit">Register!</v-btn>
                            </v-row>
                        </v-col>
                    </v-form>
                </v-card>
            </v-col>
        </v-layout>
    </v-container>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data: () => ({
    valid: false,
    e1: true,
    e2: true,
    registrationInfo: {
      user_name: '',
      email: '',
      password: ''
    },
    usernameRules: [
      (v) => !!v || 'Username is required'
    ],
    emailRules: [
      (v) => !!v || 'E-mail is required',
      // eslint-disable-next-line
      (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
    ],
    passwordRules: [
      (v) => !!v || 'Password is required',
      // eslint-disable-next-line
      (v) => /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(v) || 'Password must contain 8 characters at least 1 letter and at least 1 number'
    ]
  }),
  methods: {
    submit () {
      if (this.$refs.form.validate()) this.registerUser()
    },
    ...mapActions(['register']),
    registerUser: function () {
      this.register(this.registrationInfo)
        .then(() => {
          this.$router.push({ name: 'home' })
        })
        // .catch((error) => {
        //   console.error(error)
        // })
    }
  }
}
</script>
